<template>
  <div style="height: 100vh;">
    <iframe style="height: 100vh;" src="https://app.efexpay.com/login?organizationID=proplat" width="100%" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.landing[this.selectLanguage];
  },
  methods: {},
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.landing[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 0px;
}
</style>