<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row justify="start" align="start" class="ma-0">
        <v-col cols="12" class="pa-0">
          <div class="headline-medium text-primary">{{ texts.calendars.title }}</div>
        </v-col>
        <v-col cols="12" class="pa-0">
          <div class="body-large text-secondary">
            {{ texts.calendars.subtitle }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="rounded-xl elevation-5 ma-15 mt-5">
      <v-toolbar dense dark color="#142e53" elevation="2">
        <v-toolbar-title>
          <v-tabs v-model="selectedStatus" @change="selectedTab()" background-color="transparent" color="#FFFF" show-arrows>
              <v-tab
                class="global-tab"
                v-for="item in status"
                :key="item.sStatusId"
                :value="item.sStatusId"
              >
                {{ item.sName }}
              </v-tab>
              <v-tabs-slider></v-tabs-slider>
            </v-tabs>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn elevation="0" text small> </v-btn>
      </v-toolbar>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <div style="display: flex; justify-content: center; font-family: montserrat-bold; margin-right: 16px; font-size: 18px;">
              {{ "Total: " + "$ 300,000.00 MXN" }}
              </div>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <div style="display: flex;">
            <v-sheet height="600" width="90%">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="selectedTab"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <div v-html="selectedEvent.details"></div>
                    <div>{{ "Amount: $" + formatMoney(selectedEvent.amount) + " USD" }}</div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary" @click="selectedOpen = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          <div>
            <div style="height: 116.5px; margin-top: 16.5px; display: flex; justify-content: center; font-family: montserrat-semibold; text-align: center;">
            {{ "Total: " + "$ 60,000.00 MXN" }}
            </div>

            <div style="height: 116.5px; display: flex; justify-content: center; font-family: montserrat-semibold; text-align: center;">
              {{ "Total: " + "$ 60,000.00 MXN" }}
            </div>
            <div style="height: 116.5px; display: flex; justify-content: center; font-family: montserrat-semibold; text-align: center;">
              {{ "Total: " + "$ 60,000.00 MXN" }}
            </div>
            <div style="height: 116.5px; display: flex; justify-content: center; font-family: montserrat-semibold; text-align: center;">
              {{ "Total: " + "$ 60,000.00 MXN" }}
            </div>
            <div style="height: 116.5px; display: flex; justify-content: center; font-family: montserrat-semibold; text-align: center;">
              {{ "Total: " + "$ 60,000.00 MXN" }}
            </div>
          </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      texts: "",
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        day: "Day",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      selectedStatus: 0,
      status: [
        { sName: "Invoices", id: "Invoices" },
        { sName: "Forwards", id: "Forwards" },
      ],
    };
  },
  created() {},
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getInvoices();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getInvoices() {
      this.isLoading = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/invoices/calendar`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.isLoading = false;
        this.events = response.data.invoices.map(invoice => {
          const localStart = moment(invoice.start).format('YYYY-MM-DD');
          const localEnd = moment(invoice.end).format('YYYY-MM-DD');

          return {
            ...invoice,
            name: invoice.name,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            start: localStart,
            end: localEnd,
          };
        });
      });
    },
    getForwards() {
      this.isLoading = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/calendar`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.isLoading = false;
        this.events = response.data.transactions.map(invoice => {
          const localStart = moment(invoice.start).format('YYYY-MM-DD');
          const localEnd = moment(invoice.end).format('YYYY-MM-DD');

          return {
            ...invoice,
            name: invoice.name,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            start: localStart,
            end: localEnd,
          };
        });
      });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    selectedTab() {
      if (this.selectedStatus === 0) {
        this.getInvoices();
      } else {
        this.getForwards();
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
        this.getInvoices();
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    },
  },
};
</script>

<style scoped>
.buttonAddFounds {
  text-align: center;
  color: #0075b8;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #193D6E !important;
  height: 3px;
  color: #193D6E !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}
</style>
