import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";
import Login from "@/views/Login/index.vue";

import Welcome from "@/views/Welcome/index.vue";
import Landing from "@/views/Landing/index.vue";

import Root from "@/views/Root.vue";

import Dashboard from "@/views/Operations/Dashboard.vue";
import Invoices from "@/views/Operations/Invoices.vue";
import InvoicesReceivable from "@/views/Operations/InvoicesReceivable.vue";
import Invoice from "@/views/Operations/Invoice.vue";
import Forwards from "@/views/Operations/Forwards.vue";
import ForwardsMX from "@/views/Operations/ForwardsMX.vue";
import ActiveForward from "@/views/Operations/ActiveForward.vue";
import Balance from "@/views/Operations/Conversions.vue";
import ConversionDetail from "@/views/Operations/Conversion.vue";
import Calendars from "@/views/Operations/Calendars.vue";

import Conversions from "@/views/History/Conversions.vue";
import ConversionsMX from "@/views/History/ConversionsMX.vue";
import Conversion from "@/views/History/Conversion.vue";
import Payments from "@/views/History/Payments.vue";
import Payment from "@/views/History/Payment.vue";
import PaymentsMX from "@/views/History/PaymentsMX.vue";
import PaymentMX from "@/views/History/PaymentMX.vue";
import PaymentsIn from "@/views/History/PaymentsIn.vue";
import PaymentIn from "@/views/History/PaymentIn.vue";

import Statement from "@/views/Wallets/AccountStatement.vue";
import Suppliers from "@/views/Wallets/Suppliers.vue";
import Clients from "@/views/Wallets/Clients.vue";
import Security from "@/views/Settings/Security.vue";
import Incomes from "@/views/History/Incomes.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // When opening a new route send the user to the default location (top left)
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (Store.state.sToken) {
      if (to.name === "Login") {
        next({ name: "Root" }); // Renderiza el componente "Home"
      }
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    if (Store.state.sToken) {
      if (to.name === "Login") {
        next({ name: "Root" }); // Renderiza el componente "Home"
      }
      next();
    } else {
      next();
    }
  }
});

export default router;
