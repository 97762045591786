<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row v-if="!isLoading" justify="start" align="start" class="ma-0 mt-5">
        <v-col cols="12" class="pa-0">
          <div class="headline-medium text-primary">{{ texts.incomes.title }}</div> 
        </v-col>
        <v-col cols="12" class="pa-0">
          <div class="body-large text-secondary">
            {{ texts.incomes.subTitle }}
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        height="200px"
        width="400px"
        type="image"
      ></v-skeleton-loader>
      <v-card v-if="!isLoading" class="rounded-xl elevation-5 mt-5">
        <v-toolbar dense dark color="#142e53" elevation="2">
          <v-toolbar-title>{{ texts.incomes.title }}</v-toolbar-title> 
          <v-spacer></v-spacer>
          <v-btn text small @click="dataXLSX()">
            <h4 style="font-family: 'montserrat-bold';">{{ texts.incomes.download }}</h4>
            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer></v-spacer>
        <!-- <v-card-title style="justify-content: flex-end;">
          <div style="background: #ededed; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); border-radius: 10px; padding: 15px; border: 1px solid;">
            {{ texts.paymentsHistory.totalCurrencyGain }}
            <span class="ml-2" style="font-weight: bold;" :style="{ color: totalProfit < 0 ? 'red' : 'green'}"> 
              {{ " $" + formatMoney(totalProfit) + " USD" }}
            </span>
          </div>
        </v-card-title> -->
        <v-card-text>
          <v-spacer></v-spacer>
          <v-data-table :headers="headersForex" :items="items">
            <template v-slot:item.iTrackingId="{ item }">
              <h4
                style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
              >
                {{ item.iTrackingId }}
              </h4>
            </template>
            <template v-slot:item.sPayerName="{ item }">
              <h4
                style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
              >
                {{ item.sPayerName ? sPayerName : "N/A" }}
              </h4>
            </template>
            <template v-slot:item.created_at="{ item }">
              <h4 style="font-family: 'Montserrat', sans-serif">
                {{ formatDate(item.created_at) }}
              </h4>
            </template>

            <template v-slot:item.dAmount="{ item }">
              <h4
                style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
              >
                {{ formatMoney(item.dAmount) }} USD
              </h4>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        height="500px"
        type="image"
      ></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "IncomesView",
  data() {
    return {
      nameXLSX: "HistorialIngresos.xlsx",
      texts: "",
      loading: true,
      isLoading: false,
      items: [],
      timerCount: 30,
      headersForex: [
        this.selectLanguage === 'en' ? { text: "Id", value: "iTrackingId", align: "center", sortable: false} : { text: "Id", value: "iTrackingId", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Payer name", value: "sPayerName", align: "center"} : { text: "Payer name", value: "sPayerName", align: "center"},
        this.selectLanguage === 'en' ? { text: "Income date", value: "created_at", align: "center"} : { text: "Fecha de ingreso", value: "created_at", align: "center"},
        this.selectLanguage === 'en' ? { text: "Amount", value: "dAmount", align: "center"} : { text: "Monto", value: "dAmount", align: "center"},
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#f0be43" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold"
      };
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    getIncomes() {
        this.isLoading = true;
        DB.get(`${URI}/api/v1/${this.selectLanguage}/incomes/incomes`, {
            headers: {
            Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
        }).then((response) => {
            this.isLoading = false;
            this.items = response.data.incomes;
        });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    },
    dataXLSX() {
      try {
        let data = this.items.map((item) => {
          return {
            Id: item.iTrackingId ? item.iTrackingId : "",
            "Payer Name": item.sPayerName ? item.sPayerName : "N/A",
            "Fecha de ingreso": item.created_at ? this.formatDate(item.created_at) : "",
            "Monto (USD)": item.dAmount ? parseFloat(item.dAmount) : "",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(blob, this.nameXLSX);
        this.success = true;
        this.msgSuccess = "Descargando documento";
      } catch (error) {
        this.error = true;
        this.codeError = "CSV_NO_DATA";
      }
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    updateHeaders () {
      this.headersForex =  [
        { text: this.texts.incomes.id, value: "iTrackingId", align: "center", sortable: false},
        { text: this.texts.incomes.sPayerName, value: "sPayerName", align: "center", sortable: false},
        { text: this.texts.incomes.incomeDate, value: "created_at", align: "center"},
        { text: this.texts.incomes.amountUsd, value: "dAmount", align: "center"},
      ]    
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getIncomes();
    this.updateHeaders();
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
      this.updateHeaders();
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}
</style>
